import axios from "axios";
import { apiUrl } from "./config";
import { getToken } from "./user.auth";

const url = apiUrl();

export const getFromApiUnAuth = async (endpoint, data) => {
  try {
    if (process.env.NODE_ENV === "development") {
      console.log("#API: ", data);
      console.log(url + endpoint + "?" + data);
    }
    const response = await axios.get(url + endpoint + "?" + data, {
      timeout: 15000,
    });
    if (response.status === 200) {
      if (process.env.NODE_ENV === "development") {
        console.log("#API: ", response.data);
      }
      return response.data;
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status) {
        console.log("#API: ", error.response.data);
      }
    }
  }
  return false;
};

export const postToApiUnAuth = async (endpoint, data) => {
  try {
    if (process.env.NODE_ENV === "development") {
      console.log("#API: ", data);
    }
    const response = await axios.post(url + endpoint, data, { timeout: 15000 });
    if (response.status === 200) {
      if (process.env.NODE_ENV === "development") {
        console.log("#API: ", response.data);
      }
      return response.data;
    }
  } catch (error) {
    console.log("#API: ", error);
    if (error.response) {
      if (error.response.status) {
        console.log("#API: ", error.response.data);
      }
    }
  }

  return false;
};

export const getFromApi = async (endpoint, data, debug = true) => {
  const jwt_token = getToken();
  try {
    //axios.defaults.withCredentials = true;
    if (process.env.NODE_ENV === "development") {
      console.log("#API: ", data);
    }
    let parem = "";
    if (data !== undefined) {
      parem = "?" + data;
    }
    const response = await axios.get(url + endpoint + parem, {
      headers: {
        "X-Authorization": `Bearer ${jwt_token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      timeout: 15000,
    });
    if (response.status === 200) {
      if (process.env.NODE_ENV === "development" && debug) {
        console.log("#API: ", response.data);
      }
      return response.data;
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status) {
        if (process.env.NODE_ENV === "development" && debug) {
          console.log("#API: ", error.response.data);
        }
      }
    }
  }

  return false;
};

export const postToApi = async (endpoint, data, withresultset = false) => {
  const jwt_token = getToken();
  try {
    //axios.defaults.withCredentials = true;
    if (process.env.NODE_ENV === "development") {
      console.log("#API: ", data);
    }
    const response = await axios.post(url + endpoint, data, {
      headers: {
        "X-Authorization": `Bearer ${jwt_token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      timeout: 15000,
    });
    if (response.status === 200) {
      if (process.env.NODE_ENV === "development") {
        console.log("#API: ", response.data);
      }
      return response.data;
    }
  } catch (error) {
    // unhandled error
    console.log("#API: ", error);
    if (error.response) {
      if (error.response.status) {
        console.log("#API: ", error.response.data);
        // here are the error messages
        return withresultset ? error.response.data : false;
      }
    }
  }

  return false;
};
